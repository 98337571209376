import styled from 'styled-components';

import { ECommonColor } from '../../../../../../../../../../../common/assets/styles/colors';

const { SecondaryDarkGray } = ECommonColor;

const EmptyRows = styled.div`
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: -0.1px;
  color: ${SecondaryDarkGray};
`;

const StyledCommonComparisonRows = {
  EmptyRows,
};

export default StyledCommonComparisonRows;
