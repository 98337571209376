import { EDashboardRoute } from '../../../../routes/DashboardRoute';
import { EAuditRoute } from '../../modules/Audit/routes';
import { EAuditsRoute } from '../../routes';

const createAuditRoute = (path: EAuditRoute): string => {
  return `/${EDashboardRoute.Audits}/${EAuditsRoute.Audit}/${path}`;
};

export const AUDIT_TABS = [
  {
    id: 1,
    path: createAuditRoute(EAuditRoute.Info),
    title: 'Информация',
  },
  {
    id: 2,
    path: createAuditRoute(EAuditRoute.Tasks),
    title: 'Задачи',
  },
  {
    id: 3,
    path: createAuditRoute(EAuditRoute.Report),
    title: 'Отчёт',
  },
  {
    id: 4,
    path: createAuditRoute(EAuditRoute.Photos),
    title: 'Фотографии',
  },
];

export const AUDIT_PATH_KEY = 'auditId';
