import {
  IDynamicTableConfig,
  Stage,
} from '../../../../../../../../../../common/features/DynamicTable/models';
import { EChecklistAttributeType } from '../../../../../../../../../../../api/models/as-fields/checklists/Attribute/ChecklistAttribute.model';
import {
  IMonitoringReportTableAttribute,
  IMonitoringReportTableStage,
} from '../../../../../../../../../../../api/models/as-fields/experiments/MonitoringReportData/MonitoringReportData';
import { AttrWithCustomProp } from '../../../models/CompleteAggregateInstance/CompleteAggregateInstance';

const createAttr = (
  { id, name, values, type, isTitle }: IMonitoringReportTableAttribute,
  stageName: string
): AttrWithCustomProp => {
  const attr: AttrWithCustomProp = {
    id,
    name,
    isTitle,
    customProp: {
      stageId: `${stageName}`,
      type,
    },
  };

  if (type === EChecklistAttributeType.ChecklistInstanceLink) {
    attr.stages = values.map(({ stages, path }) => createChecklistStage(stages[0], `${path}`));
  }

  return attr;
};

const createAttrList = (
  attrOrderList: IMonitoringReportTableAttribute[],
  stageName: string
): AttrWithCustomProp[] => {
  return attrOrderList.map(attrOrder => createAttr(attrOrder, stageName));
};

function createStage({ name, attributes }: IMonitoringReportTableStage): Stage {
  return {
    id: name,
    name,
    attributes: createAttrList(attributes, name),
  };
}

function createChecklistStage(
  { name, attributes }: IMonitoringReportTableStage,
  path: string
): Stage {
  return {
    id: `${name}-${path}`,
    name,
    attributes: createAttrList(attributes, `${name}-${path}`),
  };
}

const createStageList = (stageOrderList: IMonitoringReportTableStage[]): Stage[] => {
  return stageOrderList.map(createStage);
};

const createDynamicTableConfig = (
  id: string,
  stageOrderList: IMonitoringReportTableStage[]
): IDynamicTableConfig => {
  return {
    id,
    headColumn: {
      name: 'Показатели',
      width: '240px',
      padding: id === 'auditsTaskReport' ? '16px 12px 16px 0' : '16px 12px 0 0',
      margin: '0',
      stages: createStageList(stageOrderList),
    },
    cell: {
      padding: '0 12px 0 0',
    },
    styles: {
      columnHeaderNameMargin: '0',
    },
  };
};

const DynamicTableConfigHelpers = { createDynamicTableConfig };

export default DynamicTableConfigHelpers;
