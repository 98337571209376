import { FC, memo } from 'react';

import { IAttachedFile } from '../../../../../../api/models/as-fields/experiments';
import downloadIcon from '../../../../assets/icons/download.svg';
import deleteIcon from '../../../../assets/icons/delete.svg';

import Styled from './DocumentsActions.styles';

interface IActionCellComponent {
  row: IAttachedFile;
  onClickDownloadFile: (file: IAttachedFile) => void;
  onClickDeleteFile: (fileId: string, fileName: string) => void;
  isShowDeleteButton: boolean;
}

const DocumentsActions: FC<IActionCellComponent> = ({
  row,
  onClickDownloadFile,
  onClickDeleteFile,
  isShowDeleteButton,
}) => {
  const handleDownloadClick = () => {
    onClickDownloadFile(row);
  };

  const handleDeleteClick = () => {
    onClickDeleteFile(row.id, row.fileName);
  };

  return (
    <Styled.Wrapper>
      <Styled.ActionButton onClick={handleDownloadClick}>
        <Styled.Icon src={downloadIcon} />
      </Styled.ActionButton>
      {isShowDeleteButton ? (
        <Styled.ActionButton onClick={handleDeleteClick}>
          <Styled.Icon src={deleteIcon} />
        </Styled.ActionButton>
      ) : null}
    </Styled.Wrapper>
  );
};

DocumentsActions.displayName = 'DocumentsActions';

export default memo(DocumentsActions);
