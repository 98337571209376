import { v4 as createUUID } from 'uuid';

import { lazyInject, provide } from '../../../../../../../../../../common/utils/helpers/mobx';
import {
  IComparisonTableBuilderCellConfig as ICellConfig,
  IComparisonTableBuilderConfig as IBuilderConfig,
  IComparisonTableBuilderRowsGroupConfig,
  IComparisonTableBuilderRowsGroupConfig as IRowsGroupConfig,
} from '../../../../../../../../../../common/features/ComparisonTableBuilder/models/configs';
import {
  IExperimentTotalComparisonAttribute as IAttribute,
  IExperimentTotalComparisonStage,
  IExperimentTotalComparisonStage as IStage,
  IExperimentTotalComparisonTable as ITable,
} from '../../../../../../../../../../../api/models/as-fields/experiments/ExperimentTotalComparisonTable/ExperimentTotalComparisonTable';
import { TTotalComparisonRowConfig as TRowConfig } from '../../../types';
import { TotalComparisonCellsService } from '../TotalComparisonCellsService';
import {
  TotalComparisonCollapsingRowsGroup as CollapsingRowsGroup,
  TotalComparisonContainer as Container,
  TotalComparisonFormulaRow as FormulaRow,
  TotalComparisonSummaryRowsGroup as SummaryRowsGroup,
} from '../../../components';

import Styled from './TotalComparisonRows.style';

@provide.transient()
class TotalComparisonRowsService {
  @lazyInject(TotalComparisonCellsService)
  protected cellsService: TotalComparisonCellsService;

  /**
   * Коллекция формата:
   * идентификатор ряда к списку моделей-конфигов ячеек.
   */
  protected cellConfigsByColumnIdByRowI: Map<string, ICellConfig[]> = new Map();

  get cellConfigs(): IBuilderConfig['cellConfigs'] {
    return Object.fromEntries([...this.cellConfigsByColumnIdByRowI.entries()]);
  }

  clearCellConfigsByColumnIdByRowI = (): void => {
    this.cellConfigsByColumnIdByRowI.clear();
  };

  createBlockRowsGroupConfigList = (tableList: ITable[]): IRowsGroupConfig[] => {
    return tableList.map(this.createBlockRowsGroupConfig);
  };

  protected createBlockRowsGroupConfig = ({
    id,
    name,
    stages,
    isEmpty,
  }: ITable): IRowsGroupConfig => {
    return {
      id: createUUID(),
      rowConfigList: this.createRowConfigListByStage(stages),
      customRenderConfig: {
        render: (rowsGroup, rowsChildren) => (
          <Container
            title={name}
            isWithoutBodyMargin={
              (id === 'nutritionHistory' && !isEmpty) || (id === 'nutritionSystem' && !isEmpty)
            }
          >
            {isEmpty ? <Styled.EmptyRows>Данные не внесены</Styled.EmptyRows> : rowsChildren}
          </Container>
        ),
      },
    };
  };

  protected createRowConfigListByStage = (stageList: IStage[]): TRowConfig[] => {
    return stageList.reduce<TRowConfig[]>((list, stage) => {
      if (!stage.collapsable) {
        const stageId = createUUID();

        list.push(...this.createRowConfigList(stageId, stage.attributes));
      } else {
        list.push(this.createCollapsingRowConfig(stage));
      }

      return list;
    }, []);
  };

  protected createRowConfigList = (stageId: string, attributeList: IAttribute[]): TRowConfig[] => {
    const rowConfigList = attributeList.map(attribute => {
      return this.createRowConfigByAttributeType(stageId, attribute);
    });

    return rowConfigList.filter(row => row);
  };

  protected createRowConfigByAttributeType = (
    rowsGroupId: string,
    attribute: IAttribute
  ): TRowConfig => {
    if (attribute.isTitle) {
      return this.createTitleRowConfig(rowsGroupId, attribute);
    }

    switch (attribute.type) {
      case 'nested':
        return this.createNestedRowConfig(rowsGroupId, attribute);

      case 'formula':
        return this.createFormulaRowConfig(rowsGroupId, attribute);

      case 'header':
        return this.createHeaderRowConfig(rowsGroupId, attribute);

      case 'dictionary':
        return this.createDefaultRowConfig(rowsGroupId, attribute);

      case 'double':
        return this.createDefaultRowConfig(rowsGroupId, attribute);

      case 'string':
        return this.createDefaultRowConfig(rowsGroupId, attribute);

      case 'summary':
        return this.createSummaryWrapperRowConfig(rowsGroupId, attribute);

      case 'date':
        return this.createDefaultRowConfig(rowsGroupId, attribute);

      case 'boolean':
        return this.createDefaultRowConfig(rowsGroupId, attribute);

      default:
    }
  };

  protected createDefaultRowConfig = (rowsGroupId: string, attribute: IAttribute): TRowConfig => {
    const id = `${rowsGroupId}-${attribute.id}`;

    const rowConfig: TRowConfig = {
      id,
      autoRenderConfig: {
        preset: 'default',
        name: attribute.name,
      },
      initialModel: attribute,
    };

    const cellConfigList = this.cellsService.createCellConfigList(rowConfig, attribute.values);
    this.cellConfigsByColumnIdByRowI.set(rowConfig.id, cellConfigList);

    return rowConfig;
  };

  protected createNestedRowConfig = (rowsGroupId: string, attribute: IAttribute): TRowConfig => {
    const id = `${rowsGroupId}-${attribute.id}`;

    const nestedStageList = attribute.values.flatMap(value => value.stages);

    return {
      isWithoutCells: true,
      id,
      rowsGroupConfigList: this.createNestedRowsGroupConfigList(id, nestedStageList),
      initialModel: attribute,
    };
  };

  protected createNestedRowsGroupConfigList = (
    rootRowId: string,
    stageList: IExperimentTotalComparisonStage[]
  ): IComparisonTableBuilderRowsGroupConfig[] => {
    return stageList.map(stage => this.createNestedRowsGroupConfig(rootRowId, stage));
  };

  protected createNestedRowsGroupConfig = (
    rootRowId: string,
    stage: IExperimentTotalComparisonStage
  ): IComparisonTableBuilderRowsGroupConfig => {
    const id = `${rootRowId}-${createUUID()}`;

    return {
      id,
      rowConfigList: [...this.createRowConfigList(id, stage.attributes)],
      autoRenderConfig: {
        preset: 'cleared',
      },
    };
  };

  protected createFormulaRowConfig = (rowsGroupId: string, attribute: IAttribute): TRowConfig => {
    const id = `${rowsGroupId}-${attribute.id}`;

    const rowConfig: TRowConfig = {
      id,
      customRenderConfig: {
        render: (row, dataTestId) => <FormulaRow name={attribute.name} dataTestId={dataTestId} />,
      },
      initialModel: attribute,
    };

    const cellConfigList = this.cellsService.createCellConfigList(rowConfig, attribute.values);
    this.cellConfigsByColumnIdByRowI.set(rowConfig.id, cellConfigList);

    return rowConfig;
  };

  protected createHeaderRowConfig = (rowsGroupId: string, attribute: IAttribute): TRowConfig => {
    const id = `${rowsGroupId}-${attribute.id}`;

    const rowConfig: TRowConfig = {
      id,
      isWithoutCells: true,
      autoRenderConfig: {
        preset: 'bold',
        name: attribute.name,
      },
      initialModel: attribute,
    };

    return rowConfig;
  };

  protected createTitleRowConfig = (rowsGroupId: string, attribute: IAttribute): TRowConfig => {
    const id = `${rowsGroupId}-${attribute.id}`;

    const rowConfig: TRowConfig = {
      id,
      isWithoutCells: !attribute.values,
      autoRenderConfig: {
        preset: 'bold',
        name: this.createNameOfTitleRow(attribute),
      },
      initialModel: attribute,
    };

    if (attribute.values) {
      const cellConfigList = this.cellsService.createCellConfigList(rowConfig, attribute.values);
      this.cellConfigsByColumnIdByRowI.set(rowConfig.id, cellConfigList);
    }

    return rowConfig;
  };

  protected createCollapsingRowConfig = (stage: IStage): TRowConfig => {
    const id = createUUID();

    return {
      id,
      rowsGroupConfigList: [this.createCollapsingRowsGroup(id, stage)],
    };
  };

  protected createCollapsingRowsGroup = (rootRowId: string, stage: IStage): IRowsGroupConfig => {
    const id = `${rootRowId}-${createUUID()}`;

    const rowsGroupConfig: IRowsGroupConfig = {
      id,
      rowConfigList: this.createRowConfigList(id, stage.attributes),
      customRenderConfig: {
        render: (rowsGroup, rowsChildren, dataTestId) => {
          return (
            <CollapsingRowsGroup name={stage.name} dataTestId={dataTestId}>
              {rowsChildren}
            </CollapsingRowsGroup>
          );
        },
      },
    };

    return rowsGroupConfig;
  };

  protected createSummaryWrapperRowConfig = (
    rowsGroupId: string,
    attribute: IAttribute
  ): TRowConfig => {
    const id = `${rowsGroupId}-${createUUID()}`;

    return {
      id,
      rowsGroupConfigList: [this.createSummaryRowsGroup(id, attribute)],
    };
  };

  protected createSummaryRowsGroup = (
    rootRowId: string,
    attribute: IAttribute
  ): IRowsGroupConfig => {
    const id = `${rootRowId}-${createUUID()}`;

    const rowsGroupConfig: IRowsGroupConfig = {
      id,
      rowConfigList: [this.createSummaryRowConfig(id, attribute)],
      customRenderConfig: {
        render: (rowsGroup, rowsChildren, dataTestId) => {
          return <SummaryRowsGroup dataTestId={dataTestId}>{rowsChildren}</SummaryRowsGroup>;
        },
      },
    };

    return rowsGroupConfig;
  };

  protected createSummaryRowConfig = (rowsGroupId: string, attribute: IAttribute): TRowConfig => {
    const id = `${rowsGroupId}-${attribute.id}`;

    const rowConfig: TRowConfig = {
      id,
      name: 'summary',
      autoRenderConfig: {
        preset: 'bold',
        name: attribute.name,
      },
      initialModel: attribute,
    };

    const cellConfigList = this.cellsService.createCellConfigList(rowConfig, attribute.values);
    this.cellConfigsByColumnIdByRowI.set(rowConfig.id, cellConfigList);

    return rowConfig;
  };

  protected createNameOfTitleRow = (attribute: IAttribute): string => {
    switch (attribute.type) {
      case 'dictionary': {
        const firstDictionaryValueList = attribute.values.find(
          value => value?.dictionaryValues?.length
        )?.dictionaryValues;

        if (firstDictionaryValueList) {
          return firstDictionaryValueList.find(value => value.name).name;
        }

        return attribute.name;
      }

      case 'string': {
        const name = attribute.values.find(value => value.stringValue)?.stringValue;

        if (name) {
          return name;
        }

        return attribute.name;
      }

      default:
        return attribute.name;
    }
  };
}

export default TotalComparisonRowsService;
