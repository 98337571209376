import { FC, FormEvent, MouseEvent, useEffect, useState } from 'react';
import { generatePath, useNavigate } from 'react-router-dom';
import { observer } from 'mobx-react';
import _ from 'lodash';

import { Input } from '../../../../../../../../../common/components/form';
import {
  useCheckChangedForm,
  useForm,
} from '../../../../../../../../../common/features/form/utils/hooks';
import { useStore } from '../../../../../../../../../common/utils/helpers/mobx';
import {
  createExperimentAttributesFormConfig,
  EXPERIMENTS_ATTRIBUTES_FORM_KEY,
  IExperimentAttributesForm,
} from '../../forms/config/attributesFormConfig';
import { ExperimentAttributesController } from '../../mobx/controllers';
import { ExperimentAttributesStore } from '../../mobx/stores';
import {
  useErrorModal,
  useWarningBeforeLeavingThePage,
} from '../../../../../../../../../common/utils/hooks';
import { useSelectedExp } from '../../../../hooks';
import { EExperimentsRoute } from '../../../../../../routes';
import { ECreateExperimentRoute } from '../../../../routes';
import { EDashboardRoute } from '../../../../../../../../routes/DashboardRoute';
import { EExperimentStatus } from '../../../../../../../../../../api/models/as-fields/experiments';
import { SpinnerLoader } from '../../../../../../../../../common/components/ui/loaders';
import { CreateExperimentController } from '../../../../mobx/controllers';

import { useIsAddNewChallengeButtonDisabled } from './hooks';
import Styled from './Attributes.styles';

const MAX_LONGSTRING_LENGTH = 4000;

const Attributes: FC = () => {
  const [elementIdWithError, setElementIdWithError] = useState(null);

  const { fetchExp } = useStore(CreateExperimentController);

  const {
    selectedContractor,
    currentContractorRegion,
    searchQueries,
    dropdownPageNumbers,
    contractOptionList,
    setContractOptionList,
    organizationOptionList,
    setOrganizationOptionList,
    regionOptionList,
    setRegionOptionList,
    assigneeOptionList,
    setAssigneeOptionList,
    responsibleOptionList,
    setResponsibleOptionList,
    cultureOptionList,
    setCultureOptionList,
    setCurrentContractorRegion,
  } = useStore(ExperimentAttributesStore);

  const {
    createExperiment,
    changeContractor,
    changeDataAfterContractorChange,
    clearStoreAfterLeavePage,
    contractorSearchQueryHandler,
    contractSearchQueryHandler,
    employeeSearchQueryHandler,
    regionSearchQueryHandler,
    experimentUserSearchQueryHandler,
    cultureSearchQueryHandler,
    createSeasonOptionList,
    fetchContractor,
    fetchContractorRegion,
    changeDropdownPageNumber,
    onOrganizationListScroll,
    onResponsibleListScroll,
    onRegionListScroll,
    onAssigneeListScroll,
    onContractListScroll,
    onCultureListScroll,
  } = useStore(ExperimentAttributesController);

  const selectedExp = useSelectedExp({ isClearOnUnmount: true });

  /**
   * Когда мы попали на данную страницу с уже созданным контрагентом, то, при отправке
   * данных контрагента в стор, не позволяем очищать зависимые от этого поля данные
   */
  const [isDoNotClearFields, setIsDoNotClearFields] = useState<boolean>(false);
  const [challengeList, setChallengeList] = useState<Array<string>>(['']);
  const [loading, setLoading] = useState(false);

  const { showErrorModal } = useErrorModal();
  const {
    elements,
    formData,
    isSubmitDisabled,
    registerForm,
    addOptionList,
    submitForm,
    changeListOfFormValue,
    blockElement,
    addPaginationConfig,
    getFirstElementIdWithError,
  } = useForm<IExperimentAttributesForm>(EXPERIMENTS_ATTRIBUTES_FORM_KEY);

  const { isFormChanged, focusChangedElement, setIsFormChanged } = useCheckChangedForm();
  useWarningBeforeLeavingThePage(isFormChanged, focusChangedElement);

  const isAddNewChallangeButtonDisabled = useIsAddNewChallengeButtonDisabled(challengeList);

  const {
    organizationId: Organization,
    contractId: Contract,
    responsibleId: Responsible,
    name: NameAttribute,
    type: TypeAttribute,
    seasonYear: SeasonAttribute,
    cultureId: CultureAttribute,
    regionId: Region,
    assigneeId: Assignee,
    topic: TopicAttribute,
    purpose: PurposeAttribute,
  } = elements;

  const isViewMode = selectedExp?.canEditExperiment === false;

  const isViewModeForContracts = selectedExp?.status === EExperimentStatus.FullCompleted;

  const navigate = useNavigate();

  useEffect(() => {
    if (!_.isUndefined(selectedExp)) {
      registerForm(
        createExperimentAttributesFormConfig({
          contractorSearchQueryHandler,
          contractSearchQueryHandler,
          employeeSearchQueryHandler,
          regionSearchQueryHandler,
          experimentUserSearchQueryHandler,
          cultureSearchQueryHandler,
          isViewMode,
          isViewModeForContracts,
        })
      );
    }

    return () => {
      clearStoreAfterLeavePage();
    };
  }, [isViewMode, selectedExp]);

  useEffect(() => {
    changeDataAfterContractorChange(formData?.organizationId);

    if (!isDoNotClearFields) {
      changeListOfFormValue({ contractId: '', responsibleId: '', seasonYear: null, cultureId: '' });
    } else {
      setIsDoNotClearFields(false);
    }

    (async () => {
      if (selectedExp?.wizardProgress === 'ATTRIBUTES' || !selectedExp) {
        const seasonOptionList = await createSeasonOptionList(formData?.organizationId);
        addOptionList('seasonYear', seasonOptionList);
      }
    })();
  }, [formData?.organizationId]);

  useEffect(() => {
    if (selectedExp) {
      setLoading(true);
      setIsDoNotClearFields(true);

      if (selectedExp?.challenges?.length > 0) {
        setChallengeList(selectedExp?.challenges);
      }

      changeListOfFormValue({
        name: selectedExp.name,
        type: selectedExp.type,
        seasonYear: selectedExp.seasonYear.toString(),
        organizationId: selectedExp.organization.id,
        assigneeId: selectedExp?.assignee?.id || '',
        regionId: selectedExp.region.id,
        cultureId: selectedExp.culture.id,
        purpose: selectedExp?.purpose || '',
        topic: selectedExp?.topic || '',
        responsibleId: selectedExp?.responsible?.id,
        contractId: selectedExp?.contract?.id || '',
      });

      if (selectedExp.wizardProgress !== 'ATTRIBUTES') {
        blockElement('organizationId', true);
        blockElement('cultureId', true);
        blockElement('seasonYear', true);
      }

      if (!selectedExp.canEditContract) {
        blockElement('contractId', true);
      }

      (async () => {
        const contractorOptionList = await fetchContractor(selectedExp.organization.id);
        addOptionList('organizationId', contractorOptionList);

        changeDataAfterContractorChange(selectedExp.organization.id);

        addOptionList('assigneeId', [
          { label: selectedExp.assignee?.fullName, value: selectedExp.assignee?.id },
        ]);

        addOptionList('regionId', [
          { label: selectedExp.region?.name, value: selectedExp.region?.id },
        ]);

        addOptionList('cultureId', [
          { label: selectedExp.culture?.name, value: selectedExp.culture?.id },
        ]);

        if (selectedExp?.contract) {
          addOptionList('contractId', [
            { label: selectedExp.contract?.name, value: selectedExp.contract?.id },
          ]);
        }

        if (selectedExp?.responsible) {
          addOptionList('responsibleId', [
            {
              label: selectedExp.responsible?.fullName,
              value: selectedExp.responsible?.id,
            },
          ]);
        }

        if (selectedExp?.seasonYear) {
          addOptionList('seasonYear', [
            {
              label: `${selectedExp.seasonYear - 1}-${selectedExp.seasonYear}`,
              value: selectedExp.seasonYear.toString(),
            },
          ]);
        }

        setLoading(false);
      })();
    }
  }, [selectedExp]);

  const onContractScroll = async () => {
    const newContractOptionList = await onContractListScroll(searchQueries.contractSearchQuery);

    /**
     * Добавляем новый лист опций к старому при скролле
     */
    addOptionList('contractId', [...contractOptionList, ...newContractOptionList]);

    setContractOptionList([...contractOptionList, ...newContractOptionList]);
  };

  useEffect(() => {
    addPaginationConfig('contractId', {
      currentPage: dropdownPageNumbers.contractCurrentPageNumber,
      totalPages: dropdownPageNumbers.contractTotalPageNumber,
      onScroll: onContractScroll,
      onPageChange: () => changeDropdownPageNumber('contractCurrentPageNumber'),
    });
  }, [
    dropdownPageNumbers.contractCurrentPageNumber,
    dropdownPageNumbers.contractTotalPageNumber,
    onContractScroll,
    changeDropdownPageNumber,
  ]);

  const onResponsibleScroll = async () => {
    const newResponsibleOptionList = await onResponsibleListScroll(
      searchQueries.responsibleSearchQuery
    );

    /**
     * Добавляем новый лист опций к старому при скролле
     */
    addOptionList('responsibleId', [...responsibleOptionList, ...newResponsibleOptionList]);

    setResponsibleOptionList([...responsibleOptionList, ...newResponsibleOptionList]);
  };

  useEffect(() => {
    addPaginationConfig('responsibleId', {
      currentPage: dropdownPageNumbers.responsibleCurrentPageNumber,
      totalPages: dropdownPageNumbers.responsibleTotalPageNumber,
      onScroll: onResponsibleScroll,
      onPageChange: () => changeDropdownPageNumber('responsibleCurrentPageNumber'),
    });
  }, [
    dropdownPageNumbers.responsibleCurrentPageNumber,
    dropdownPageNumbers.responsibleTotalPageNumber,
    onResponsibleScroll,
    changeDropdownPageNumber,
  ]);

  const onCultureScroll = async () => {
    const newCultureOptionList = await onCultureListScroll(searchQueries.cultureSearchQuery);

    /**
     * Добавляем новый лист опций к старому при скролле
     */
    addOptionList('cultureId', [...cultureOptionList, ...newCultureOptionList]);

    setCultureOptionList([...cultureOptionList, ...newCultureOptionList]);
  };

  useEffect(() => {
    addPaginationConfig('cultureId', {
      currentPage: dropdownPageNumbers.cultureCurrentPageNumber,
      totalPages: dropdownPageNumbers.cultureTotalPageNumber,
      onScroll: onCultureScroll,
      onPageChange: () => changeDropdownPageNumber('cultureCurrentPageNumber'),
    });
  }, [
    dropdownPageNumbers.cultureCurrentPageNumber,
    dropdownPageNumbers.cultureTotalPageNumber,
    onCultureScroll,
    changeDropdownPageNumber,
  ]);

  const onAssigneeScroll = async () => {
    const newAssigneeOptionList = await onAssigneeListScroll(searchQueries.assigneeSearchQuery);

    /**
     * Добавляем новый лист опций к старому при скролле
     */
    addOptionList('assigneeId', [...assigneeOptionList, ...newAssigneeOptionList]);

    setAssigneeOptionList([...assigneeOptionList, ...newAssigneeOptionList]);
  };

  useEffect(() => {
    addPaginationConfig('assigneeId', {
      currentPage: dropdownPageNumbers.assigneeCurrentPageNumber,
      totalPages: dropdownPageNumbers.assigneeTotalPageNumber,
      onScroll: onAssigneeScroll,
      onPageChange: () => changeDropdownPageNumber('assigneeCurrentPageNumber'),
    });
  }, [
    dropdownPageNumbers.assigneeCurrentPageNumber,
    dropdownPageNumbers.assigneeTotalPageNumber,
    onAssigneeScroll,
    changeDropdownPageNumber,
  ]);

  const onOrganizationScroll = async () => {
    const newOrganizationOptionList = await onOrganizationListScroll(
      searchQueries.organizationSearchQuery
    );

    /**
     * Добавляем новый лист опций к старому при скролле
     */
    addOptionList('organizationId', [...organizationOptionList, ...newOrganizationOptionList]);

    setOrganizationOptionList([...organizationOptionList, ...newOrganizationOptionList]);
  };

  useEffect(() => {
    addPaginationConfig('organizationId', {
      currentPage: dropdownPageNumbers.organizationCurrentPageNumber,
      totalPages: dropdownPageNumbers.organizationTotalPageNumber,
      onScroll: onOrganizationScroll,
      onPageChange: () => changeDropdownPageNumber('organizationCurrentPageNumber'),
    });
  }, [
    dropdownPageNumbers.organizationCurrentPageNumber,
    dropdownPageNumbers.organizationTotalPageNumber,
    onOrganizationScroll,
    changeDropdownPageNumber,
  ]);

  const onRegionScroll = async () => {
    const newRegionOptionList = await onRegionListScroll(searchQueries.regionQuerySearchQuery);

    /**
     * Добавляем новый лист опций к старому при скролле
     */
    addOptionList('regionId', [...regionOptionList, ...newRegionOptionList]);

    setRegionOptionList([...regionOptionList, ...newRegionOptionList]);
  };

  useEffect(() => {
    addPaginationConfig('regionId', {
      currentPage: dropdownPageNumbers.regionCurrentPageNumber,
      totalPages: dropdownPageNumbers.regionTotalPageNumber,
      onScroll: onRegionScroll,
      onPageChange: () => changeDropdownPageNumber('regionCurrentPageNumber'),
    });
  }, [
    dropdownPageNumbers.regionCurrentPageNumber,
    dropdownPageNumbers.regionTotalPageNumber,
    onRegionScroll,
    changeDropdownPageNumber,
  ]);

  useEffect(() => {
    if (selectedContractor?.INN) {
      fetchContractorRegion(selectedContractor.INN.substring(0, 2));
    } else {
      setCurrentContractorRegion(null);
    }
  }, [selectedContractor]);

  const addNewChallange = (e: MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    setChallengeList([...challengeList, '']);
  };

  const handleSubmit = async (event: FormEvent): Promise<void> => {
    event.preventDefault();

    setIsFormChanged(false);

    await submitForm(async validatedData => {
      if (isViewMode) {
        await changeContractor(validatedData.contractId)
          .then(() => fetchExp(selectedExp.id))
          .catch(() => {
            setIsFormChanged(true);
          });
      } else {
        await createExperiment(validatedData, challengeList, showErrorModal)
          .then(experiment => {
            if (!selectedExp) {
              navigate(
                generatePath(
                  `/${EDashboardRoute.Experiments}/${EExperimentsRoute.Experiment}/${ECreateExperimentRoute.Attributes}`,
                  {
                    experimentId: experiment.id,
                  }
                )
              );
            } else {
              fetchExp(experiment.id);
            }
          })
          .catch(() => {
            setIsFormChanged(true);
          });
      }
    }).then(() => {
      const elementId = getFirstElementIdWithError();

      if (!elementId.includes('undefined')) {
        setIsFormChanged(false);

        setElementIdWithError(elementId);
      }
    });
  };

  useEffect(() => {
    if (!elementIdWithError) return;

    const element = document.getElementById(elementIdWithError);

    const y = element?.getBoundingClientRect()?.top;

    window.scrollTo({ top: y, behavior: 'smooth' });
  }, [elementIdWithError]);

  const onChallengeChange = (challengeNumber: number, inputValue: string) => {
    setIsFormChanged(true);

    const newChallengeList = [...challengeList];

    newChallengeList[challengeNumber] = inputValue;

    setChallengeList(newChallengeList);
  };

  if (selectedExp && loading) return <SpinnerLoader needToHideContent={false} />;

  return (
    <Styled.Wrapper onSubmit={handleSubmit} data-test-id="requisites-wrapper">
      <Styled.Container data-test-id="requisites-container">
        <Styled.Header data-test-id="requisites-header">
          <Styled.Title data-test-id="requisites-header-title">Контрагент</Styled.Title>
        </Styled.Header>
        <Styled.Body data-test-id="requisites-body">
          <Styled.Attribute
            marginBottom="16px"
            marginRight="12px"
            data-test-id="requisites-attribute-organization"
          >
            {Organization ? <Organization data-test-id="requisites-organization" /> : null}
          </Styled.Attribute>
          <Styled.AttributeWrapper>
            <Styled.Attribute
              data-test-id="requisites-attribute-organization-type"
              width={'40%'}
              marginRight="12px"
            >
              <Input
                label="Правовая форма"
                value={selectedContractor?.type?.OrgType || 'Не указана'}
                isBlocked
                data-test-id="requisites-organization-type"
              />
            </Styled.Attribute>
            <Styled.Attribute data-test-id="requisites-attribute-inn" width={'40%'}>
              <Input
                label="ИНН"
                value={selectedContractor?.INN || 'ИНН не указан'}
                isBlocked
                data-test-id="requisites-inn"
              />
            </Styled.Attribute>
          </Styled.AttributeWrapper>
          <Styled.Attribute
            marginBottom="16px"
            marginRight="12px"
            data-test-id="requisites-attribute-region"
          >
            <Input
              label="Регион"
              value={currentContractorRegion?.name || 'Регион не указан'}
              isBlocked
              data-test-id="requisites-region"
            />
          </Styled.Attribute>
          <Styled.Attribute data-test-id="requisites-attribute-address">
            <Input
              label="Адрес"
              value={selectedContractor?.addressJur?.addressfull || 'Адрес не указан'}
              isBlocked
              data-test-id="requisites-adress"
            />
          </Styled.Attribute>
          <Styled.Attribute marginRight="12px" data-test-id="requisites-attribute-contract">
            {Contract ? <Contract data-test-id="requisites-contract" /> : null}
          </Styled.Attribute>
          <Styled.Attribute data-test-id="requisites-attribute-responsible">
            {Responsible ? <Responsible data-test-id="requisites-responsible" /> : null}
          </Styled.Attribute>
        </Styled.Body>
      </Styled.Container>

      <Styled.Container data-test-id="requisites-description-wrapper">
        <Styled.Header data-test-id="requisites-description-header">
          <Styled.Title data-test-id="requisites-description-header-title">
            Описание опыта
          </Styled.Title>
        </Styled.Header>
        <Styled.Body data-test-id="requisites-description-body">
          <Styled.Attribute
            marginBottom="16px"
            marginRight="12px"
            data-test-id="requisites-description-attribute-name"
          >
            {NameAttribute ? <NameAttribute data-test-id="requisites-description-name" /> : null}
          </Styled.Attribute>
          <Styled.Attribute
            data-test-id="requisites-description-attribute-type"
            marginBottom="16px"
          >
            {TypeAttribute ? <TypeAttribute data-test-id="requisites-description-type" /> : null}
          </Styled.Attribute>
          <Styled.Attribute
            marginBottom="16px"
            marginRight="12px"
            data-test-id="requisites-description-attribute-season"
          >
            {SeasonAttribute ? (
              <SeasonAttribute data-test-id="requisites-description-season" />
            ) : null}
          </Styled.Attribute>
          <Styled.Attribute
            data-test-id="requisites-description-attribute-culture"
            marginBottom="16px"
          >
            {CultureAttribute ? (
              <CultureAttribute data-test-id="requisites-description-culture" />
            ) : null}
          </Styled.Attribute>
          <Styled.Attribute
            marginBottom="16px"
            marginRight="12px"
            data-test-id="requisites-description-attribute-region"
          >
            {Region ? <Region data-test-id="requisites-description-region" /> : null}
          </Styled.Attribute>
          <Styled.Attribute
            data-test-id="requisites-description-attribute-assignee"
            marginBottom="16px"
          >
            {Assignee ? <Assignee data-test-id="requisites-description-assignee" /> : null}
          </Styled.Attribute>
          <Styled.Attribute
            width="100%"
            marginBottom="16px"
            data-test-id="requisites-description-attribute-topic"
          >
            {TopicAttribute ? <TopicAttribute data-test-id="requisites-description-topic" /> : null}
          </Styled.Attribute>
          <Styled.Attribute
            width="100%"
            marginBottom="16px"
            data-test-id="requisites-description-attribute-purpose"
          >
            {PurposeAttribute ? (
              <PurposeAttribute data-test-id="requisites-description-purpose" />
            ) : null}
          </Styled.Attribute>
          <Styled.ChallengesWrapper>
            <Styled.SubTitle data-test-id="requisites-description-header-subtitle">
              Задачи опыта
            </Styled.SubTitle>

            {challengeList.map((value, index) => (
              <Styled.ChallengeContainer key={index}>
                <Input
                  label={`Задача №${index + 1}`}
                  id={`Задача №${index + 1}`}
                  value={value}
                  placeholder={'Не указана'}
                  onChange={inputValue => onChallengeChange(index, inputValue)}
                  as={'textarea'}
                  height={'100px'}
                  isBlocked={isViewMode}
                  hideScrollbar
                  isResizable={false}
                  maxLength={MAX_LONGSTRING_LENGTH}
                  data-test-id="requisites-challenges-textarea-input"
                />
              </Styled.ChallengeContainer>
            ))}
            {!isViewMode && (
              <Styled.AddButton
                onClick={addNewChallange}
                type={'button'}
                disabled={isViewMode || isAddNewChallangeButtonDisabled}
              >
                Добавить еще одну задачу исследования
              </Styled.AddButton>
            )}
          </Styled.ChallengesWrapper>
        </Styled.Body>
      </Styled.Container>

      <Styled.ButtonWrapper
        color="primary"
        type="submit"
        disabled={isSubmitDisabled || !isFormChanged}
        data-test-id="preparation-button-wrapper"
      >
        {selectedExp ? 'Сохранить' : 'Создать опыт'}
      </Styled.ButtonWrapper>
    </Styled.Wrapper>
  );
};

Attributes.displayName = 'Attributes';

export default observer(Attributes);
