import styled from 'styled-components';

import { Container } from '../../../../../../../common/features/UI';

const Wrapper = styled(Container)<{ $flex?: boolean }>`
  display: flex;
  flex: ${({ $flex }) => ($flex ? 1 : 0)};
  flex-direction: column;
`;

const StyledAuditPhotos = { Wrapper };

export default StyledAuditPhotos;
