import { FC } from 'react';
import { observer } from 'mobx-react';

import { EExperimentStatus } from '../../../../../../../../../../api/models/as-fields/experiments';
import { useStore } from '../../../../../../../../../common/utils/helpers/mobx';
import { DocumentsTableStore } from '../../../../../../../../../common/features/DocumentsTable/mobx/stores';
import { DocumentsTable } from '../../../../../../../../../common/features/DocumentsTable';
import { useSelectedExp } from '../../../../../createExperiment/hooks';

import Styled from './Documents.styles';

const Documents: FC = observer(() => {
  const selectedExp = useSelectedExp({ isClearOnUnmount: true });
  const { attachedFiles } = useStore(DocumentsTableStore);

  const hideActionButtons = selectedExp?.status === EExperimentStatus.FullCompleted;

  return (
    <Styled.Wrapper $isFlexible={attachedFiles?.length === 0} data-test-id="preparation-wrapper">
      <DocumentsTable
        entity="experiment"
        id={selectedExp?.id}
        hideDownloadButton={hideActionButtons}
        hideActionButtons={hideActionButtons}
      />
    </Styled.Wrapper>
  );
});

Documents.displayName = 'Documents';

export default Documents;
