import { FC, useEffect } from 'react';
import { observer } from 'mobx-react';
import { Tabs, useContextualHelpActions } from '@farmlink/farmik-ui';

import { useStore } from '../../../../../../../../../common/utils/helpers/mobx';
import AuditReportController from '../../mobx/controller/AuditReport.controller';
import AuditReportStore from '../../mobx/stores/AuditReport.store';
import { AuditStore } from '../../../../mobx/store';
import {
  ContextualPaths,
  EContextualParentPath,
} from '../../../../../../../../../common/constants/contextualPath';
import { usePageContextualHelp } from '../../../../../../../../../common/hooks/usePageContextualHelp';

import Styled from './AuditReportHeaderActions.styles';

const AuditReportHeaderActions: FC = () => {
  const auditReportStore = useStore(AuditReportStore);
  const { selectedTabId, setSelectedTabId } = useStore(AuditStore);
  const auditReportController = useStore(AuditReportController);

  usePageContextualHelp(EContextualParentPath.AuditsAuditReportAudit);

  const helpActions = useContextualHelpActions();

  useEffect(() => {
    if (auditReportStore.selectedTabId) {
      setSelectedTabId(auditReportStore.selectedTabId);
    }
  }, [auditReportStore.selectedTabId]);

  const onChangeTab = selectedId => {
    window.event.preventDefault();

    auditReportController.changeTab(selectedId);
  };

  const ContextualHelpIcon = helpActions.getContextualHelpIcon(
    EContextualParentPath.AuditsAuditReportAudit,
    ContextualPaths.AuditsAuditReportAuditComparisonTableTitle
  );

  return (
    <Styled.Wrapper>
      <Styled.TitleWrapper>
        <Styled.Title>
          Сравнение по участкам
          <Styled.Icon>{ContextualHelpIcon ? ContextualHelpIcon : null}</Styled.Icon>
        </Styled.Title>

        {auditReportStore.availableTabList.length > 1 ? (
          <Tabs
            tabWidth="97.33px"
            selectedTabId={selectedTabId}
            onChangeTab={onChangeTab}
            content={auditReportStore.availableTabList}
            size="small"
            dataTestId="test"
          />
        ) : null}
      </Styled.TitleWrapper>
    </Styled.Wrapper>
  );
};

AuditReportHeaderActions.displayName = 'AuditReportHeaderActions';

export default observer(AuditReportHeaderActions);
