import { FC } from 'react';
import { useParams } from 'react-router-dom';
import { observer } from 'mobx-react';
import { useContextualHelpActions } from '@farmlink/farmik-ui';

import { EExperimentStatus } from '../../../../../../../../api/models/as-fields/experiments';
import { useStore } from '../../../../../../../common/utils/helpers/mobx';
import { DocumentsTableStore } from '../../../../../../../common/features/DocumentsTable/mobx/stores';
import { DocumentsTable } from '../../../../../../../common/features/DocumentsTable';
import { TAuditsParams } from '../../../../models/params/AuditsParams.model';
import { AuditStore } from '../../mobx/store';
import {
  ContextualPaths,
  EContextualParentPath,
} from '../../../../../../../common/constants/contextualPath';
import { usePageContextualHelp } from '../../../../../../../common/hooks/usePageContextualHelp';

import Styled from './AuditDocuments.styles';

const AuditDocuments: FC = observer(() => {
  const { auditId } = useParams<TAuditsParams>();

  const { selectedAudit } = useStore(AuditStore);
  const { attachedFiles } = useStore(DocumentsTableStore);

  const hideActionButtons = selectedAudit?.experiment?.status === EExperimentStatus.FullCompleted;

  const helpActions = useContextualHelpActions();

  usePageContextualHelp(EContextualParentPath.AuditsAuditDocuments);

  const ContextualHelpIcon = helpActions.getContextualHelpIcon(
    EContextualParentPath.AuditsAuditDocuments,
    ContextualPaths.AuditsAuditDocumentsTableTitle
  );

  return (
    <Styled.Wrapper $isFlexible={attachedFiles.length === 0} data-test-id="preparation-wrapper">
      <DocumentsTable
        entity="audit"
        id={auditId}
        hideActionButtons={hideActionButtons}
        ContextualHelpIcon={ContextualHelpIcon}
      />
    </Styled.Wrapper>
  );
});

AuditDocuments.displayName = 'AuditDocuments';

export default AuditDocuments;
