import styled from 'styled-components';

const InputRowWrapper = styled.form`
  width: 100%;
  display: flex;
  margin-top: 16px;
  gap: 16px;

  & > div {
    width: 100%;
  }
`;

const Styled = {
  InputRowWrapper,
};

export default Styled;
