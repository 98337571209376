import { FC, useMemo } from 'react';
import { observer } from 'mobx-react';
import { Link, useParams } from 'react-router-dom';
import { AutoTooltip, Icon, useShowTooltip } from '@farmlink/farmik-ui';

import { InstanceComponentProps } from '../../../../../../../../../common/features/DynamicTable/models/Instance/Instance.model';
import { generateAuditsPath } from '../../../../../../utils/helpers';
import { TAuditsParams } from '../../../../../../models/params/AuditsParams.model';
import { EExperimentCultureZoneType } from '../../../../../../../../../../api/models/as-fields/experiments/ExperimentCultureZone';

import Styled from './AuditReportColumnNameComponent.styles';

const AuditReportColumnNameComponent: FC<InstanceComponentProps<any>> = ({ instance }) => {
  const { auditId } = useParams<TAuditsParams>();

  const isTaskIdHaveInformation = useMemo(
    () => Boolean(instance?.customProp?.taskId),
    [instance?.customProp?.taskId]
  );

  const goToTaskReport = useMemo(() => {
    if (isTaskIdHaveInformation) {
      const taskId = instance?.customProp?.taskId ?? '';

      return generateAuditsPath('taskReport', { auditId, taskId });
    }
  }, [instance]);

  const { ref, isShowTooltip } = useShowTooltip();

  const numberOfPointsName = useMemo(() => {
    if (instance?.customProp?.numberOfPoints % 100 === 1) {
      return `${instance?.customProp?.numberOfPoints} точка`;
    } else if ([2, 3, 4].includes(instance?.customProp?.numberOfPoints)) {
      return `${instance?.customProp?.numberOfPoints} точки`;
    } else {
      return `${instance?.customProp?.numberOfPoints} точек`;
    }
  }, [instance?.customProp?.numberOfPoints]);

  const isShowNumberOfPoints = useMemo(() => {
    return instance?.customProp?.numberOfPoints && isTaskIdHaveInformation;
  }, [instance?.customProp?.numberOfPoints, isTaskIdHaveInformation]);

  const iconType = useMemo(() => {
    return instance.customProp?.expZoneType === EExperimentCultureZoneType.Control
      ? 'controlField'
      : 'experimentField';
  }, [instance.customProp?.expZoneType]);

  return (
    <Styled.Wrapper>
      <Styled.ColumnNameWrapper>
        <Styled.IconWrapper>
          <Icon icon={iconType} size={'medium'} />
        </Styled.IconWrapper>

        <AutoTooltip content={instance.name} disabled={!isShowTooltip}>
          <Styled.NameWrapper ref={ref}>{instance.name}</Styled.NameWrapper>
        </AutoTooltip>
      </Styled.ColumnNameWrapper>

      {isShowNumberOfPoints ? (
        <Link to={goToTaskReport}>
          <Styled.NumberOfPointsWrapper>{numberOfPointsName}</Styled.NumberOfPointsWrapper>
        </Link>
      ) : null}
    </Styled.Wrapper>
  );
};

AuditReportColumnNameComponent.displayName = 'AuditReportColumnNameComponent';

export default observer(AuditReportColumnNameComponent);
