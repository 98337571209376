import { observer } from 'mobx-react';
import { FC, memo, useMemo } from 'react';
import { generatePath, useNavigate, useParams } from 'react-router-dom';
import { saveAs } from 'file-saver';
import {
  Colors,
  DropdownButton,
  ENotificationType,
  ENotificatorTheme,
  TButtonMultiAction,
  useNotificator,
} from '@farmlink/farmik-ui';

import { ExperimentStepsService } from '../../../../../../../common/mobx/services/as-fields';
import { AuditStore } from '../../mobx/store';
import { useStore } from '../../../../../../../common/utils/helpers/mobx';
import { ObservationController } from '../../../../containers/Observations/mobx/controllers';
import { EDashboardRoute } from '../../../../../../routes/DashboardRoute';
import { generateAuditsPath } from '../../../../utils/helpers';
import { useWarningBeforeDeleting } from '../../../../../../../common/utils/hooks';
import { EClearedPaths } from '../../utils/constants';
import useClearedCurrentPath from '../../hooks/path/useClearedCurrentPath/useClearedCurrentPath';
import { EAuditRoute } from '../../routes';

import Styled from './HeaderContent.style';

const HeaderContent: FC = observer(() => {
  const { selectedAudit } = useStore(AuditStore);

  const { deleteAudit } = useStore(ObservationController);

  const experimentStepsService = useStore(ExperimentStepsService);
  const { generateMonitoringReport } = experimentStepsService;

  const { showWarningBeforeDeleting } = useWarningBeforeDeleting();

  const { clearedCurrentPath } = useClearedCurrentPath();

  const navigate = useNavigate();

  const { setNotification } = useNotificator();

  const params = useParams();

  const isPermissionToDelete = useMemo(() => {
    return (
      selectedAudit?.id &&
      selectedAudit?.canDelete &&
      clearedCurrentPath !== EClearedPaths.editAudit
    );
  }, [selectedAudit, clearedCurrentPath]);

  const generateMonitoring = async () => {
    try {
      const response = await generateMonitoringReport(params.auditId);

      const blob = new Blob([response], {
        type: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
      });

      saveAs(blob, selectedAudit.name);
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error(e);
    }
  };

  const deleteAuditAction = async () => {
    await deleteAudit(selectedAudit?.id);

    setNotification({
      message: 'Наблюдение удалено',
      style: {
        placement: 'top-center',
        type: ENotificationType.Success,
        theme: ENotificatorTheme.Dark,
      },
    });

    navigate(generateAuditsPath(EDashboardRoute.Audits));
  };

  const deleteAuditWarning = () => {
    showWarningBeforeDeleting('наблюдение', deleteAuditAction);
  };

  const navigateToApproval = () => {
    navigate(
      generatePath(`${EAuditRoute.Approval}`, {
        auditId: params?.auditId,
      })
    );
  };

  const navigateToDocuments = () => {
    navigate(
      generatePath(`${EAuditRoute.Documents}`, {
        auditId: params?.auditId,
      })
    );
  };

  const isNotShowMonitoringButton =
    params?.auditId === 'create' ||
    clearedCurrentPath === EClearedPaths.editAudit ||
    clearedCurrentPath === `${EDashboardRoute.Audits}/${EAuditRoute.Approval}` ||
    clearedCurrentPath === `${EDashboardRoute.Audits}/${EAuditRoute.Documents}` ||
    clearedCurrentPath === `${EClearedPaths.taskReportClearedPath}`;

  const buttonActions: TButtonMultiAction[] = [
    {
      title: 'В чат согласования',
      onClick: navigateToApproval,
    },
    {
      title: 'Документы',
      onClick: navigateToDocuments,
    },
    {
      title: 'Скачать отчет',
      onClick: generateMonitoring,
    },
    {
      title: 'Удалить',
      onClick: deleteAuditWarning,
      textColor: Colors.accentPink,
      isHidden: !isPermissionToDelete,
    },
  ];

  return (
    <Styled.StyledWrapper>
      {isNotShowMonitoringButton ? null : (
        <DropdownButton
          title={'Действия'}
          multiActionList={buttonActions}
          dataTestId="fullscreen-action-button"
          style={{ size: 'sm', placement: 'bottom', color: 'primary' }}
        />
      )}
    </Styled.StyledWrapper>
  );
});

export default memo(HeaderContent);
