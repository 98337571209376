import { FC, useCallback, useMemo, useState, useRef } from 'react';
import { Colors, IconButton } from '@farmlink/farmik-ui';
import { IColorScheme } from '@farmlink/farmik-ui/dist/IconButton/IconButton.styles';

import { Input } from '../../../../components/form';

import Styled from './CommentInput.styles';

const submitColorScheme: IColorScheme = {
  default: {
    background: Colors.blue,
    icon: Colors.generalWhite,
  },
  hover: {
    background: Colors.hoverBlue,
    icon: Colors.generalWhite,
  },
  disabled: {
    background: Colors.borderGray,
    icon: Colors.generalWhite,
  },
};

interface ICommentInputProps {
  /**
   * Функция отправки комментария.
   * Верните `false` для того, чтобы очистить поле ввода.
   */
  onSubmit?: (value: string) => false | void;
}

const CommentInput: FC<ICommentInputProps> = ({ onSubmit }) => {
  const [value, setValue] = useState('');

  const inputRef = useRef<HTMLInputElement>();

  const onSendData = useCallback(
    (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      e.preventDefault();

      if (onSubmit) {
        const state = onSubmit(value);

        if (state === false) {
          setValue('');
        }
      }
    },
    [onSubmit, value]
  );

  const inputHeight = useMemo(() => {
    if (!value) {
      return 40;
    }

    return inputRef.current?.scrollHeight;
  }, [value]);

  return (
    <Styled.InputRowWrapper>
      <Input
        ref={inputRef}
        className="chat-comment-input"
        as="textarea"
        dataTestId="chat-comment-input"
        placeholder="Напишите комментарий"
        rows={3}
        value={value}
        onChange={setValue}
        height={`${inputHeight}px`}
        maxLength={500}
        isResizable={false}
        hideScrollbar
      />
      <IconButton
        onClick={onSendData}
        colorScheme={submitColorScheme}
        size={40}
        icon="arrow_forward"
        type="submit"
        data-test-id="chat-comment-submit"
        $isDisabled={!Boolean(value.trim())}
        hasStroke
      ></IconButton>
    </Styled.InputRowWrapper>
  );
};

export default CommentInput;
export type { ICommentInputProps };
