import { ReactNode } from 'react';

import { provide } from '../../../../../../../../common/utils/helpers/mobx';
import {
  ITableFiltersBuilderConfig,
  ITableFiltersBuilderHeaderConfig as IHeaderConfig,
} from '../../../../../../../../common/features/TableFiltersBuilder/models/configs';
import { IAuditsFilters } from '../../../models';
import { EAuditsFiltersBuilderId } from '../../../../../constants/configs';
import { TAuditsFiltersFilterConfig as TFilterConfig } from '../../../types';
import {
  TFiltersFilterPaginationQueryItem as TPageQueryItem,
  TFiltersFilterPaginationQuery as TPageQueryConfig,
} from '../../../../../../../../common/features/TableFiltersBuilder/types/pagination/FiltersFilterPaginationConfig';

type TOmittedAuditFilterQuery = TPageQueryConfig<IAuditsFilters, 'statusIn'>;

@provide.transient()
class AuditsFiltersConfigsService {
  createConfig = (
    onActionButtonClick: () => void,
    paginationQueryConfig: TOmittedAuditFilterQuery
  ): ITableFiltersBuilderConfig<IAuditsFilters> => {
    const headerConfig = this.createHeaderConfig(onActionButtonClick);
    const filterList = this.createFilterList(paginationQueryConfig);

    return {
      id: EAuditsFiltersBuilderId.Audits,
      headerConfig,
      filterConfigList: filterList,
    };
  };

  protected createHeaderConfig = (onActionButtonClick: () => void): IHeaderConfig => {
    return {
      title: 'Наблюдения',
      iconsConfig: {
        isShowToggleFilters: true,
      },
      actionButtonConfig: {
        autoRenderConfig: {
          title: 'Создать наблюдение',
          onClick: onActionButtonClick,
        },
      },
    };
  };

  protected createFilterList = (
    paginationQueryConfig: TOmittedAuditFilterQuery
  ): TFilterConfig[] => {
    return [
      this.createTechOperationTypeFilter({
        paginationConfig: paginationQueryConfig.techOperationTypeIdIn,
      }),
      this.createOrganizationFilter({
        paginationConfig: paginationQueryConfig.organizationIdIn,
      }),
      this.createAssigneeFilter({
        paginationConfig: paginationQueryConfig.assigneeIdIn,
      }),
      this.createExperimentFilter({
        paginationConfig: paginationQueryConfig.experimentIdIn,
      }),
      this.createStatusFilter(),
    ];
  };

  protected createTechOperationTypeFilter = ({
    paginationConfig,
  }: {
    paginationConfig: TPageQueryItem;
  }): TFilterConfig => {
    return {
      id: 'techOperationTypeIdIn',
      name: 'Тип операции',
      type: 'select',
      selectOptions: {
        pagination: this.getOptionPaginationConfig(paginationConfig),
      },
    };
  };

  protected createOrganizationFilter = ({
    paginationConfig,
  }: {
    paginationConfig: TPageQueryItem;
  }): TFilterConfig => {
    return {
      id: 'organizationIdIn',
      name: 'Контрагент',
      type: 'select',
      selectOptions: {
        pagination: this.getOptionPaginationConfig(paginationConfig),
      },
    };
  };

  protected createAssigneeFilter = ({
    paginationConfig,
  }: {
    paginationConfig: TPageQueryItem;
  }): TFilterConfig => {
    return {
      id: 'assigneeIdIn',
      name: 'Ответственный',
      type: 'select',
      selectOptions: {
        pagination: this.getOptionPaginationConfig(paginationConfig),
      },
    };
  };

  protected createExperimentFilter = ({
    paginationConfig,
  }: {
    paginationConfig: TPageQueryItem;
  }): TFilterConfig => {
    return {
      id: 'experimentIdIn',
      name: '№ опыта',
      type: 'select',
      selectOptions: {
        pagination: this.getOptionPaginationConfig(paginationConfig),
      },
    };
  };

  protected createStatusFilter = (): TFilterConfig => {
    return { id: 'statusIn', name: 'Статус', type: 'select' };
  };

  private getOptionPaginationConfig = (paginationConfig: TPageQueryItem) => ({
    updateDataHandler: data => paginationConfig?.updateHandler?.(data),
    requestQuery: (page, searchQuery) => paginationConfig.requestHandler(page, searchQuery),
  });
}

export default AuditsFiltersConfigsService;
