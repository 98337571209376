import { flowRight } from 'lodash';

import { IExperimentCultureZone } from '../../../../../../../../../../../api/models/as-fields/experiments';
import { ITask } from '../../../../../../../../../../../api/models/as-fields/task/Task';
import { ICompleteAggregateAttrWithVal, ICompleteAggregateInstance } from '../../../models';
import { EChecklistAttributeType } from '../../../../../../../../../../../api/models/as-fields/checklists/Attribute/ChecklistAttribute.model';

import {
  IMonitoringReportData,
  IMonitoringReportDataZone,
  IMonitoringReportTableAttribute,
  IMonitoringReportTableStage,
} from './../../../../../../../../../../../api/models/as-fields/experiments/MonitoringReportData/MonitoringReportData';

const isTaskOfZone = (expZone: IExperimentCultureZone, task: ITask): boolean => {
  return expZone.cultureZone?.technology?.id === task?.operationInfo?.technology?.id;
};

const createAttrWithVal = (
  attribute: IMonitoringReportTableAttribute,
  stageId: string
): ICompleteAggregateAttrWithVal[] => {
  return [
    {
      dynamicTableAttr: { ...attribute, stageId },
      checklistAttrVal: attribute.values,
    },
  ];
};

function createAttrWithValList(
  stageList: IMonitoringReportTableStage[]
): ICompleteAggregateAttrWithVal[] {
  const attrWithValList: ICompleteAggregateAttrWithVal[] = [];

  stageList.forEach(({ attributes, name }) => {
    attributes.forEach(attribute => {
      if (attribute.type === EChecklistAttributeType.ChecklistInstanceLink) {
        const attrWithValListFromChecklist = attribute?.values.map(({ stages, path }) =>
          createAttrWithValListFromChecklist(stages, path)
        );

        attrWithValListFromChecklist.forEach(attrWithValFromChecklist =>
          attrWithValList.push(...attrWithValFromChecklist)
        );
      } else {
        const attrWithVal = createAttrWithVal(attribute, `${name}`);

        if (attrWithVal) {
          attrWithValList.push(...attrWithVal);
        }
      }
    });
  });

  return attrWithValList;
}

function createAttrWithValListFromChecklist(
  stageList: IMonitoringReportTableStage[],
  path: string
): ICompleteAggregateAttrWithVal[] {
  const attrWithValList: ICompleteAggregateAttrWithVal[] = [];

  stageList.forEach(({ attributes, name }) => {
    attributes.forEach(attribute => {
      const attrWithVal = createAttrWithVal(attribute, `${name}-${path}`);

      if (attrWithVal) {
        attrWithValList.push(...attrWithVal);
      }
    });
  });

  return attrWithValList;
}

const addAttrWithVal = (
  stageList: IMonitoringReportTableStage[],
  cultureZoneListFromData: IMonitoringReportDataZone[]
) => {
  return (completeInstList: ICompleteAggregateInstance[]): ICompleteAggregateInstance[] => {
    return completeInstList.map((completeInst, index) => ({
      ...completeInst,
      customProps: {
        researchDate: cultureZoneListFromData[index].researchDate,
        numberOfPoints: cultureZoneListFromData[index].completedChecklistQuantity,
      },
      attrWithValList: createAttrWithValList(stageList),
    }));
  };
};

const mapZonesToTasks = (
  expZoneList: IExperimentCultureZone[],
  taskList: ITask[]
): ICompleteAggregateInstance[] => {
  return expZoneList.map<ICompleteAggregateInstance>(expZone => ({
    expZone,
    task: taskList.find(task => isTaskOfZone(expZone, task)),
  }));
};

const createCompleteInstList = (
  expZoneList: IExperimentCultureZone[],
  taskList: ITask[],
  reportData: IMonitoringReportData
): ICompleteAggregateInstance[] => {
  const { table, experimentZones } = reportData;

  const composition = flowRight(addAttrWithVal(table.stages, experimentZones), mapZonesToTasks);

  return composition(expZoneList, taskList);
};

const completeAggregateInstanceHelpers = {
  createCompleteInstList,
};

export default completeAggregateInstanceHelpers;
