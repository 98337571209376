import { FC, useEffect, useMemo, useState } from 'react';
import { observer } from 'mobx-react';
import { useModal, Tabs, ButtonLink, useContextualHelpActions } from '@farmlink/farmik-ui';

import { EExperimentStatus } from '../../../../../../../../../../api/models/as-fields/experiments';
import { useStore } from '../../../../../../../../../common/utils/helpers/mobx';
import { useSelectedExp, useZonesPlugResolver } from '../../../../hooks';
import {
  useWarningBeforeDeleting,
  useWarningBeforeLeavingThePage,
} from '../../../../../../../../../common/utils/hooks';
import { TableBuilder } from '../../../../../../../../../common/features/TableBuilder';
import { EExperimentsTableBuilderId } from '../../../../../../constants/configs';
import { TableBuilderController } from '../../../../../../../../../common/features/TableBuilder/mobx/controllers';
import { SliderContext } from '../../../history/containers/History/context';
import { useSliderControl } from '../../../history/containers/History/hooks';
import { createActiveSubstancesTableBuilderData } from '../../../../../../../../../common/components/ActiveSubstancesTable/helpers/createActiveSubstancesTableBuilderData';
import { createTechOperationsTableBuilderData } from '../../../../../../../../../common/components/TechOperationsTable/helpers/createTechOperationsTableBuilderData';
import { IActiveSubstancesData } from '../../../../../../../../../common/components/ActiveSubstancesTable/model/IActiveSubstancesData.model';
import { createEconomyTableBuilderData } from '../../../../../../../../../common/components/EconomyTable/helpers/createEconomyTableBuilderData';
import { ConfigurableSlider } from '../../../../../../../../../common/components/ui';
import { SpinnerLoader } from '../../../../../../../../../common/components/ui/loaders';
import { usePageContextualHelp } from '../../../../../../../../../common/hooks/usePageContextualHelp';
import {
  ContextualPaths,
  EContextualParentPath,
} from '../../../../../../../../../common/constants/contextualPath';
import { EExperimentStepType } from '../../../../../../../../../../api/models/as-fields/experiments/ExperimentStep/ExperimentStep.model';
import { EExperimentCultureZoneType } from '../../../../../../../../../../api/models/as-fields/experiments/ExperimentCultureZone';

import { NO_ZONES_TITLE, NOT_SELECTED_ZONES_TITLE } from './constants/titles';
import { CarouselItem } from './components/CarouselIem';
import techOperationModalList from './config/modals/techOperationModalList';
import { tabContent } from './constants';
import { createCarouselItems } from './helpers/createCarouselItems';
import { CalculationController } from './mobx/controllers/Calculation/Calculation.controller';
import { CalculationStore } from './mobx/store/Calculation/Calculation.store';
import { AgrochemicalTable } from './components/AgrochemicalTable';
import { createAgrochemicalTableConfig } from './helpers/createAgrochemicalTableConfig';
import { InfoBlock, InfoForCurrentSection, InfoForCompareSections } from './components';
import { useCalculation } from './hooks/useCalculation';
import TablePlug from './components/TechOperationPlug/TablePlug';
import Styled from './Calculation.styles';

const Calculation: FC = () => {
  const {
    cultureZones,
    activeCultureZone,
    activeTab,
    activeTabName,
    nutritionHistories,
    experimentSteps,
    examinationAttributeValues,
    currentExamination,
    experimentStepListOfControlZone,
    nutritionHistoryListOfControlZone,
    getExperimentStepById,
    getNutritionHistoryItemById,
    clearStore,
  } = useStore(CalculationStore);

  const {
    fetchCultureZones,
    onChangeActiveCultureZone,
    onChangeActiveTab,
    fetchExperimentSteps,
    fetchNutritionHistories,
    deleteExperimentStep,
    deleteInventoryValue,
    onChangeInventoryValue,
    onChangeTechOperation,
    fetchExaminationAttributeValues,
    updateCultureZoneExperiment,
    initiateTable,
    deleteAho,
  } = useStore(CalculationController);

  const tableBuilderController = useStore(TableBuilderController);

  const { openModalByModalId, registerModalList } = useModal();

  const selectedExp = useSelectedExp({ isClearOnUnmount: true });

  const isViewOnly = useMemo(() => !selectedExp?.canEditExperiment, [selectedExp]);

  const { currentZone, changed, valuesChanged, setValuesChanged } = useCalculation({
    cultureZones,
    activeCultureZone,
    experimentSteps,
    experimentStepListOfControlZone,
    nutritionHistories,
    nutritionHistoryListOfControlZone,
  });

  const sliderControl = useSliderControl();

  const {
    loading,
    showPlug,
    plugType,
    plugTitle,
    Plug: ZonesPlug,
  } = useZonesPlugResolver(selectedExp?.id, NO_ZONES_TITLE, NOT_SELECTED_ZONES_TITLE);

  usePageContextualHelp(EContextualParentPath.ExperimentsExperimentCalculation);
  const helpActions = useContextualHelpActions();

  const { showWarningBeforeDeleting } = useWarningBeforeDeleting();

  const ContextualHelpIcon = helpActions.getContextualHelpIcon(
    EContextualParentPath.ExperimentsExperimentCalculation,
    ContextualPaths.ExperimentsExperimentCalculationNutritionSystemTitle
  );

  const onChangeCultureZone = (id: string) => {
    (async () => {
      await updateCultureZoneExperiment(activeCultureZone, currentZone);
      await fetchCultureZones({ experimentId: selectedExp.id }, activeCultureZone.id);
      onChangeActiveCultureZone(id);
    })();
  };

  const carouselItems = useMemo(() => createCarouselItems(cultureZones), [cultureZones]);

  const currentCultureZoneId = useMemo(() => activeCultureZone?.id ?? '', [activeCultureZone]);

  const [builderId, setBuilderId] = useState(EExperimentsTableBuilderId.CalculationTechOperations);

  const onDeleteInventoryValue = (stepId: string, fertilizerId: string) => {
    deleteInventoryValue(stepId, fertilizerId, {
      experimentId: selectedExp?.id,
      cultureZoneExperimentId: currentCultureZoneId,
    });
  };

  const onEditInventoryValue = (id: string) => {
    const nutritionHistory = getNutritionHistoryItemById(id);
    onChangeInventoryValue(nutritionHistory);
    openModalByModalId(`editPlanInventoryValues`);
  };

  const tableBuilderData = useMemo(() => {
    if (activeTab === 'activeSubstance') {
      return createActiveSubstancesTableBuilderData(experimentSteps, nutritionHistories);
    } else if (activeTab === 'techOperation') {
      return createTechOperationsTableBuilderData(experimentSteps, nutritionHistories);
    } else {
      return createEconomyTableBuilderData(experimentSteps, nutritionHistories);
    }
  }, [experimentSteps, nutritionHistories, activeTab]);

  const agrochemicalRows = useMemo(
    () => createAgrochemicalTableConfig(currentExamination, examinationAttributeValues),
    [currentExamination, examinationAttributeValues]
  );

  useEffect(() => {
    if (selectedExp) {
      fetchCultureZones({ experimentId: selectedExp.id });
    }
  }, [selectedExp]);

  useEffect(() => {
    if (activeCultureZone && selectedExp) {
      const isControlZOne = activeCultureZone.type === EExperimentCultureZoneType.Control;

      fetchExperimentSteps(
        {
          experimentId: selectedExp.id,
          type: EExperimentStepType.Plan,
          cultureZoneExperimentId: activeCultureZone.id,
        },
        activeCultureZone,
        isControlZOne
      );

      fetchExaminationAttributeValues(activeCultureZone.id);

      fetchNutritionHistories(
        {
          experimentId: selectedExp.id,
          cultureZoneExperimentId: activeCultureZone.id,
          experimentStepType: EExperimentStepType.Plan,
        },
        isControlZOne
      );
    }
  }, [activeCultureZone, selectedExp]);

  useEffect(() => {
    tableBuilderController.showLoader(builderId);

    tableBuilderController.addRowsToRowsGroupListById(builderId, builderId, tableBuilderData, {
      isClearPreviousList: true,
    });

    tableBuilderController.hideLoader(builderId);
  }, [tableBuilderData, builderId]);

  const onClickAddTechoperation = (row: Pick<IActiveSubstancesData, 'id'>) => {
    const experimentStep = getExperimentStepById(row.id);
    onChangeTechOperation(experimentStep);
    openModalByModalId(`addPlanInventoryValues`);
  };

  const onClickDeleteTechoperation = (row: Pick<IActiveSubstancesData, 'id' | 'name'>) => {
    showWarningBeforeDeleting(`техоперацию : «${row.name}»`, () =>
      deleteExperimentStep(
        row.id,
        {
          experimentId: selectedExp.id,
          type: EExperimentStepType.Plan,
        },
        activeCultureZone
      )
    );
  };

  const onClickEditTechoperation = (row: Pick<IActiveSubstancesData, 'id'>) => {
    const experimentStep = getExperimentStepById(row.id);
    onChangeTechOperation(experimentStep);
    openModalByModalId(`editPlanTechOperation`);
  };

  const onClickDeleteInventoryValue = (row, fertilizerId) => {
    const inventoryValueName = row.children.find(
      inventoryValue => inventoryValue.fertilizer.id === fertilizerId
    )?.fertilizer.name;

    showWarningBeforeDeleting(`ТМЦ : «${inventoryValueName}»`, () =>
      onDeleteInventoryValue(row.id, fertilizerId)
    );
  };

  const onClickEditInventoryValue = fertilizerId => {
    onEditInventoryValue(fertilizerId);
  };

  useEffect(() => {
    let newBuilderId = EExperimentsTableBuilderId.CalculationEconomy;

    if (activeTab === 'activeSubstance') {
      newBuilderId = EExperimentsTableBuilderId.CalculationActiveSubstances;
    } else if (activeTab === 'techOperation') {
      newBuilderId = EExperimentsTableBuilderId.CalculationTechOperations;
    }

    setBuilderId(newBuilderId);

    initiateTable(
      onClickAddTechoperation,
      onClickDeleteTechoperation,
      onClickEditTechoperation,
      onClickDeleteInventoryValue,
      onClickEditInventoryValue,
      newBuilderId,
      isViewOnly
    );
  }, [activeTab, nutritionHistories, experimentSteps]);

  useEffect(() => {
    registerModalList(techOperationModalList, 'nutritionHistory');
  }, []);

  const onClickAdd = () => {
    openModalByModalId('addPlanTechOperation');
  };

  const onClickCopy = () => {
    openModalByModalId('copyFieldPlan');
  };

  const onCreateAgrochemicalValue = () => {
    openModalByModalId('createAxoModal');
  };

  const onEditAgrochemicalValue = () => {
    openModalByModalId('editAxoModal');
  };

  const onDeleteAgrochemicalValue = () => {
    deleteAho(activeCultureZone);
  };

  const onCopyAgrochemicalValue = () => {
    openModalByModalId('copyAhoModal');
  };

  const onSuccess = async () => {
    await updateCultureZoneExperiment(activeCultureZone, currentZone);
    await setValuesChanged(false);
  };

  useWarningBeforeLeavingThePage(valuesChanged);

  // Очищаем стор шага "Расчет" после ухода со страницы
  useEffect(() => {
    return () => {
      clearStore();
    };
  }, []);

  const isShowUnderTableButtons = useMemo(() => {
    return !isViewOnly && tableBuilderData.length > 0;
  }, [isViewOnly, tableBuilderData]);

  if (loading) return <SpinnerLoader needToHideContent={false} />;

  if (showPlug) {
    return <ZonesPlug type={plugType} title={plugTitle} />;
  }

  return (
    <Styled.Wrapper data-test-id={`calculation-main-wrapper`}>
      <ConfigurableSlider dataTestId="calculation-culture-zones">
        {carouselItems.map(item => {
          return (
            <CarouselItem
              id={item.id}
              key={item.id}
              name={item.name}
              description={item.description}
              selected={item.id === currentCultureZoneId}
              img={item.img}
              handleSelectItem={onChangeCultureZone}
              sections={cultureZones}
            />
          );
        })}
      </ConfigurableSlider>
      <Styled.Container data-test-id={`calculation-main-container`}>
        <Styled.TitleWrapper data-test-id={`calculation-title-wrapper`}>
          <Styled.Title data-test-id={`calculation-title`}>
            Система питания
            {ContextualHelpIcon ? ContextualHelpIcon : null}
          </Styled.Title>
          {isShowUnderTableButtons && (
            <ButtonLink
              color={'accent'}
              onClick={onClickCopy}
              dataTestId={`calculation-copy-button`}
            >
              <Styled.CopyIcon data-test-id={`calculation-copy-icon`} />
              Скопировать данные с другого участка
            </ButtonLink>
          )}
        </Styled.TitleWrapper>
        {tableBuilderData.length > 0 ? (
          <>
            <Styled.TabsWrapper>
              <Tabs
                selectedTabId={activeTab}
                onChangeTab={onChangeActiveTab}
                stylePreset={'secondary'}
                content={tabContent}
                size="medium"
                dataTestId="calculation-tabs"
              />
            </Styled.TabsWrapper>
            <Styled.SubTitleWrapper data-test-id={`calculation-subtitle-wrapper`}>
              <Styled.SubTitle data-test-id={`calculation-title-${activeTabName}`}>
                {activeTabName}
              </Styled.SubTitle>
            </Styled.SubTitleWrapper>
            <SliderContext.Provider value={sliderControl}>
              <TableBuilder builderId={builderId} stylePreset={'cleared'} borderType={'dashed'} />
            </SliderContext.Provider>
          </>
        ) : (
          <TablePlug onClickAdd={onClickAdd} />
        )}
        {isShowUnderTableButtons && (
          <Styled.AddTechOperationButton
            color={'primary'}
            styleType={'outlined'}
            type={'button'}
            onClick={onClickAdd}
            alignment={'center'}
            dataTestId={`calculation-tech-operation-button`}
          >
            Добавить техоперацию
          </Styled.AddTechOperationButton>
        )}
      </Styled.Container>
      <InfoBlock
        title={'Вводные расчеты по текущему участку'}
        infoBody={
          <InfoForCurrentSection
            currentZone={currentZone}
            changed={changed}
            isViewMode={isViewOnly}
          />
        }
      />
      {activeCultureZone?.type !== EExperimentCultureZoneType.Control ? (
        <InfoBlock
          title={'Параметры сравнения опытного и контрольного участков'}
          infoBody={<InfoForCompareSections changed={changed} />}
        />
      ) : null}
      <AgrochemicalTable
        onClickAdd={onCreateAgrochemicalValue}
        onClickEdit={onEditAgrochemicalValue}
        onClickDelete={onDeleteAgrochemicalValue}
        onClickCopy={onCopyAgrochemicalValue}
        isViewOnly={isViewOnly}
        rows={agrochemicalRows}
      />
      {selectedExp?.status === EExperimentStatus.Draft && (
        <Styled.Button color="primary" onClick={onSuccess} disabled={!currentZone?.valuesChanged}>
          Сохранить
        </Styled.Button>
      )}
    </Styled.Wrapper>
  );
};

export default observer(Calculation);
