import { provide } from '../../../../../../../../../../common/utils/helpers/mobx';
import {
  ITableBuilderCellConfig as ICellConfig,
  ITableBuilderColumnConfig as IColumnConfig,
  ITableBuilderRowsGroupConfig as IRowsGroupConfig,
  ITableBuilderConfig as IBuilderConfig,
} from '../../../../../../../../../../common/features/TableBuilder/models/configs';
import { EAuditsTableBuilderId } from '../../../../../../../constants/configs';
import {
  OperationTypeCell,
  StatusCellComponent,
} from '../../../../Info/modules/auditInfo/helpers/components';
import { FieldCell } from '../../../../../../../../../../common/components/ui/cells';

@provide.transient()
class TasksListConfigsService {
  createConfig = (): IBuilderConfig => ({
    id: EAuditsTableBuilderId.Tasks,
    columnConfigList: this.createColumnList(),
    cellConfigList: this.createCellList(),
    rowsGroupConfigList: this.createRowsGroupList(),
  });

  protected createColumnList = (): IColumnConfig[] => [
    this.createTechOperationColumn(),
    this.createFieldColumn(),
    this.createAuthorColumn(),
    this.createExecutorColumn(),
    this.createCultureColumn(),
    this.createStatusColumn(),
  ];

  protected createTechOperationColumn = (): IColumnConfig => ({
    id: 'operationInfo',
    name: 'Тип операции',
    width: {
      default: '200px',
    },
  });

  protected createFieldColumn = (): IColumnConfig => ({
    id: 'field',
    name: 'Участок',
    padding: '0 18px',
    width: {
      default: '125px',
    },
  });

  protected createAuthorColumn = (): IColumnConfig => ({
    id: 'createdBy',
    name: 'Автор',
    width: {
      default: '148px',
    },
  });

  protected createExecutorColumn = (): IColumnConfig => ({
    id: 'assignee',
    name: 'Исполнитель',
    width: {
      default: '148px',
    },
  });

  protected createCultureColumn = (): IColumnConfig => ({
    id: 'culture',
    name: 'Культура',
    width: {
      default: '148px',
    },
  });

  protected createStatusColumn = (): IColumnConfig => ({
    id: 'status',
    name: 'Статус',
    width: {
      default: '95px',
    },
  });

  protected createCellList = (): ICellConfig[] => [
    this.createTechOperationCell(),
    this.createFieldCell(),
    this.createAuthorCell(),
    this.createExecutorCell(),
    this.createCultureCell(),
    this.createStatusCell(),
  ];

  createTechOperationCell = (): ICellConfig => ({
    id: 'operationInfo',
    customRenderConfig: {
      renderCell: row => <OperationTypeCell rowData={row} />,
    },
  });

  createFieldCell = (): ICellConfig => ({
    id: 'field',
    customRenderConfig: {
      renderCell: row => <FieldCell rowData={row} />,
    },
  });

  createAuthorCell = (): ICellConfig => ({
    id: 'createdBy',
    autoRenderConfig: {
      renderValue: row => row?.createdBy?.fullName,
    },
  });

  createExecutorCell = (): ICellConfig => ({
    id: 'assignee',
    autoRenderConfig: {
      renderValue: row => row?.assignee?.fullName,
    },
  });

  createCultureCell = (): ICellConfig => ({
    id: 'culture',
    autoRenderConfig: {
      renderValue: row => row?.culture?.name,
    },
  });

  createStatusCell = (): ICellConfig => ({
    id: 'status',
    customRenderConfig: {
      renderCell: row => <StatusCellComponent status={row.status} />,
    },
  });

  createRowsGroupList = (): IRowsGroupConfig[] => {
    return [
      {
        id: EAuditsTableBuilderId.Tasks,
        rowConfigList: [],
        customRenderConfig: {
          render: (_, rowsChildren) => <>{rowsChildren}</>,
        },
      },
    ];
  };
}

export default TasksListConfigsService;
