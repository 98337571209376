import { FC, useCallback, useEffect } from 'react';
import { observer } from 'mobx-react';
import { useContextualHelpActions } from '@farmlink/farmik-ui';

import { Container } from '../../../../../../../common/features/UI';
import { ITask } from '../../../../../../../../api/models/as-fields/task/Task';
import { systemHelperCollection } from '../../../../../../../common/utils/helpers/system';
import { useStore } from '../../../../../../../common/utils/helpers/mobx';
import { Plug } from '../../../../../../../common/components/ui';
import noDataIcon from '../../../../../../../common/components/table/assets/icons/noDataIcon.svg';
import { AuditStore } from '../../mobx/store';
import { TableBuilder } from '../../../../../../../common/features/TableBuilder';
import { EAuditsTableBuilderId } from '../../../../constants/configs';
import { TableBuilderController } from '../../../../../../../common/features/TableBuilder/mobx/controllers';
import {
  ContextualPaths,
  EContextualParentPath,
} from '../../../../../../../common/constants/contextualPath';
import { usePageContextualHelp } from '../../../../../../../common/hooks/usePageContextualHelp';

import Styled from './AuditTasks.styles';
import { AuditTasksController } from './mobx/controller';
import { AuditTasksStore } from './mobx/store';

const { goToAssistanceTask } = systemHelperCollection;

const AuditTasks: FC = () => {
  const { selectedAudit, selectedExp } = useStore(AuditStore);
  const { taskList, clearAuditTasksStore } = useStore(AuditTasksStore);

  const { fetchTaskList, initiateTable } = useStore(AuditTasksController);

  const { addRowClickEvent, showLoader, addRowsToRowsGroupListById, hideLoader } =
    useStore(TableBuilderController);

  const handleRowClick = useCallback((task: ITask) => {
    goToAssistanceTask(task.organizationId, task.id);
  }, []);

  useEffect(() => {
    initiateTable();

    return () => {
      clearAuditTasksStore();
    };
  }, []);

  useEffect(() => {
    if (selectedAudit && selectedExp) {
      fetchTaskList();
    }
  }, [selectedAudit, selectedExp]);

  useEffect(() => {
    showLoader(EAuditsTableBuilderId.Tasks);

    addRowClickEvent(EAuditsTableBuilderId.Tasks, handleRowClick);

    addRowsToRowsGroupListById(EAuditsTableBuilderId.Tasks, EAuditsTableBuilderId.Tasks, taskList, {
      isClearPreviousList: true,
    });

    hideLoader(EAuditsTableBuilderId.Tasks);
  }, [taskList]);

  usePageContextualHelp(EContextualParentPath.AuditsAuditTasks);
  const helpActions = useContextualHelpActions();

  const ContextualHelpIcon = helpActions.getContextualHelpIcon(
    EContextualParentPath.AuditsAuditTasks,
    ContextualPaths.AuditsAuditTasksTableTitle
  );

  return (
    <Styled.Wrapper>
      <Container
        title={'Список задач'}
        isHideHeaderBorder={true}
        ContextualHelpIcon={ContextualHelpIcon}
      >
        <Styled.TableBody>
          <TableBuilder
            builderId={EAuditsTableBuilderId.Tasks}
            stylePreset={'cleared'}
            renderDefaultPlug={() => (
              <Plug
                icon={noDataIcon}
                title="У вас еще нет задач"
                primaryButtonTitle="Добавить"
                // eslint-disable-next-line no-console
                onPrimaryButtonClick={() => console.log('go to')}
              />
            )}
            renderNoDataPlug={() => (
              <Plug
                icon={noDataIcon}
                title="У вас еще нет задач"
                primaryButtonTitle="Добавить"
                // eslint-disable-next-line no-console
                onPrimaryButtonClick={() => console.log('go to')}
              />
            )}
          />
        </Styled.TableBody>
      </Container>
    </Styled.Wrapper>
  );
};

AuditTasks.displayName = 'AuditTasks';

export default observer(AuditTasks);
