import styled from 'styled-components';

const StyledWrapper = styled.div`
  display: flex;
  width: 160px;
`;

const StyledHeaderContent = {
  StyledWrapper,
};

export default StyledHeaderContent;
