import { FC, useCallback, useEffect } from 'react';
import { observer } from 'mobx-react';

import { TFileEntities } from '../../../../../../api/models/as-fields/attachedFiles';
import { IAttachedFile } from '../../../../../../api/models/as-fields/experiments';
import { useStore } from '../../../../utils/helpers/mobx';
import { useWarningBeforeDeleting } from '../../../../utils/hooks';
import { TableBuilder } from '../../../TableBuilder';
import { TableBuilderController } from '../../../TableBuilder/mobx/controllers';
import { TTableBuilderStylePreset as TTableStylePreset } from '../../../TableBuilder/types/styles';
import { DocumentsTableController } from '../../mobx/controllers';
import { DocumentsTableStore } from '../../mobx/stores';
import { DocumentsNoDataPlug } from '../DocumentsNoDataPlug';

interface IProps {
  id: string;
  entity: TFileEntities;
  hideActionButtons?: boolean;
  stylePreset?: TTableStylePreset;
}

export const DocumentsList: FC<IProps> = observer(
  ({ id, entity, hideActionButtons, stylePreset }) => {
    const tableBuilderController = useStore(TableBuilderController);

    const { initiateTable, downloadAttachedFile, deleteAttachedFile } =
      useStore(DocumentsTableController);

    const { fetchAttachedFiles } = useStore(DocumentsTableController);

    const { attachedFiles, clearStore } = useStore(DocumentsTableStore);

    const { showWarningBeforeDeleting } = useWarningBeforeDeleting();

    const downloadFileAction = (file: IAttachedFile) => {
      downloadAttachedFile(file);
    };

    const deleteFileAction = useCallback(
      (fileId: string, fileName: string) => {
        showWarningBeforeDeleting(fileName, () => deleteAttachedFile(id, fileId, entity));
      },
      [id]
    );

    useEffect(() => {
      if (!id) return;

      fetchAttachedFiles(id, entity);
    }, [id, entity]);

    useEffect(() => {
      tableBuilderController.addRowsToRowsGroupListById('documents', 'documents', attachedFiles, {
        isClearPreviousList: true,
      });
    }, [attachedFiles]);

    useEffect(() => {
      initiateTable(downloadFileAction, deleteFileAction, !hideActionButtons);
    }, [id]);

    useEffect(() => {
      return () => {
        clearStore();
      };
    }, []);

    return (
      <TableBuilder
        builderId="documents"
        renderNoDataPlug={() => (
          <DocumentsNoDataPlug isShowButton={!hideActionButtons} id={id} entity={entity} />
        )}
        renderDefaultPlug={() => (
          <DocumentsNoDataPlug isShowButton={!hideActionButtons} id={id} entity={entity} />
        )}
        stylePreset={stylePreset}
      />
    );
  }
);
