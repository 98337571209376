import styled from 'styled-components';

const Wrapper = styled.div<{ $isFlexible?: boolean }>`
  max-width: 912px;
  border-radius: 16px;
  padding-top: 24px;
  background: ${({ theme }) => theme.Colors.generalLight ?? '#ffffff'};
  display: flex;
  flex-direction: column;
  flex: ${({ $isFlexible }) => ($isFlexible ? 1 : 0)};
  margin-bottom: 100px;
`;

const StyledPreparation = {
  Wrapper,
};

export default StyledPreparation;
