import styled from 'styled-components';

import { ECommonColor } from '../../../../../../../../../common/assets/styles/colors';

const { AccentGreen } = ECommonColor;

const Wrapper = styled.span`
  display: flex;
  flex-direction: column;
  min-height: 48px;
`;

const NumberOfPointsWrapper = styled.span`
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: -0.15px;

  color: ${AccentGreen};
`;

const ColumnNameWrapper = styled.div`
  padding-bottom: 12px;
  align-items: center;
  display: flex;
  position: relative;
`;

const NameWrapper = styled.span`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 170px;
`;

const IconWrapper = styled.div`
  margin-right: 8px;
`;

const StyledAuditReportColumnNameComponent = {
  NumberOfPointsWrapper,
  ColumnNameWrapper,
  Wrapper,
  IconWrapper,
  NameWrapper,
};

export default StyledAuditReportColumnNameComponent;
